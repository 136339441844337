import startingSlashSettings from '@/mixins/startingSlashSettings';
import { isCleanUrlInRulesEnabled } from '@/utils/features';
import { mapState } from 'vuex';

export default {
  mixins: [startingSlashSettings],
  computed: {
    ...mapState(['account']),
    features() {
      return this.account && this.account.features ? this.account.features : [];
    },
  },

  methods: {
    domainPrefix(expressionIndex) {
      return this.needStartingSlash(this.currentRule.options.expression[expressionIndex].operator)
        ? this.domainWithDash
        : null;
    },
    modifyExpressionValueV2(callback) {
      const toIterate = this.currentRule.options;

      Object.values(toIterate).forEach((expressionGroup) => {
        if (Array.isArray(expressionGroup)) {
          expressionGroup.forEach((expression) => {
            if (expression.pageType && expression.pageType !== 'page') return;
            if (isCleanUrlInRulesEnabled(this.features)) {
              expression.value = this.cleanUrl(expression.value);
            }

            if (this.needStartingSlash(expression.operator)) {
              expression.value = callback(expression.value);
            }
          });
        } else {
          const expression = expressionGroup;
          if (isCleanUrlInRulesEnabled(this.features)) {
            expression.value = this.cleanUrl(expression.value);
          }
          if (this.needStartingSlash(expression.operator)) {
            expression.value = callback(expression.value);
          }
        }
      });
    },
    modifyExpressionValue(callback) {
      if (
        this.currentRule.options.includeExpressions ||
        this.currentRule.options.excludeExpressions
      ) {
        this.modifyExpressionValueV2(callback);
        return;
      }

      const toIterate = this.currentRule.options.expressions || this.currentRule.options.expression;

      toIterate.forEach((expressionGroup) => {
        if (Array.isArray(expressionGroup)) {
          expressionGroup.forEach((expression) => {
            if (expression.pageType && expression.pageType !== 'page') return;
            if (isCleanUrlInRulesEnabled(this.features)) {
              expression.value = this.cleanUrl(expression.value);
            }
            if (this.needStartingSlash(expression.operator)) {
              expression.value = callback(expression.value);
            }
          });
        } else {
          const expression = expressionGroup;
          if (isCleanUrlInRulesEnabled(this.features)) {
            expression.value = this.cleanUrl(expression.value);
          }
          if (this.needStartingSlash(expression.operator)) {
            expression.value = callback(expression.value);
          }
        }
      });
    },
    cleanUrl(url) {
      if (url?.startsWith('http://') || url?.startsWith('https://')) {
        const urlObj = new URL(url);
        return urlObj.pathname + urlObj.search + urlObj.hash;
      }
      return url;
    },
    beforeSaveCallback() {
      this.modifyExpressionValue(this.addStartingSlash);
    },
  },

  created() {
    this.modifyExpressionValue(this.removeStartingSlash);
  },
};
