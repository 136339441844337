<template lang="pug">
div
  .row.align-items-center.mb-4(
    v-for="(expression, expressionIndex) in currentRule.options.expression"
  )
    .col-6
      om-select(
        :id="`ipAddressType-${expressionIndex}`"
        :options="ipAddressTypes"
        size="small"
        @input="setIpAddressType($event, expressionIndex)"
        :value="{ key: currentRule.options.expression[expressionIndex].type, value: $t(`ip.${currentRule.options.expression[expressionIndex].type}`) }"
      )
    .col-6
      .d-flex.align-items-center
        template(v-if="currentRule.options.expression[expressionIndex].type === 'range'")
          om-input(
            :id="`inRangeInputStart-${expressionIndex}`"
            :error="$v.currentRule.options.expression.$each[expressionIndex].value.$error"
            :value="getIntervalValue(currentRule.options.expression[expressionIndex].value, 0)"
            @change.native="setIntervalValue(expressionIndex, $event.target.value, 0)"
            :title="getErrorMessage(expressionIndex)"
            :show-invalid-suffix="false"
          )
          span.mx-2 -
          om-input(
            :id="`inRangeInputEnd-${expressionIndex}`"
            :error="$v.currentRule.options.expression.$each[expressionIndex].value.$error"
            :value="getIntervalValue(currentRule.options.expression[expressionIndex].value, 1)"
            @change.native="setIntervalValue(expressionIndex, $event.target.value, 1)"
            :title="getErrorMessage(expressionIndex)"
            :show-invalid-suffix="false"
          )
        template(v-else)
          om-input.w-100(
            :id="`specificRangeInput-${expressionIndex}`"
            :error="$v.currentRule.options.expression.$each[expressionIndex].value.$error"
            v-model="currentRule.options.expression[expressionIndex].value"
            :title="getErrorMessage(expressionIndex)"
            :show-invalid-suffix="false"
          )
        .brand-link.settings-label.ml-4(
          v-if="expressionLength > 1 && expressionIndex"
          @click="removeExpression('expression', expressionIndex)"
        )
          fa-icon(variant="fa-trash" size="1.5")
        .brand-link.settings-label.px-4(v-else)
  span.brand-link.settings-label(
    @click="addExpression('expression', { type: 'specific', value: '' })"
  )
    fa-icon(variant="fa-plus" :html="`&nbsp;${$t('addNew')}`")
</template>

<script>
  import settingsValidation from '@/mixins/settingsValidation';
  import expressionOperations from '@/mixins/expressionOperations';

  const ipRegex =
    /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

  export default {
    mixins: [settingsValidation, expressionOperations],
    data() {
      return {
        ipAddressTypes: [
          { key: 'specific', value: this.$t('ip.specific') },
          { key: 'range', value: this.$t('ip.inRange') },
        ],
      };
    },
    computed: {
      expressionLength() {
        return this.currentRule.options.expression.length;
      },
    },
    validations: {
      currentRule: {
        options: {
          expression: {
            $each: {
              value: {
                isCool(v, { type, value }) {
                  if (type === 'range') {
                    const range = value.split('|');
                    const isFormatsValid = ipRegex.test(range[0]) && ipRegex.test(range[1]);
                    if (isFormatsValid) {
                      const sumStart = range[0].split('.').reduce((acc, c) => acc + +c, 0);
                      const sumEnd = range[1].split('.').reduce((acc, c) => acc + +c, 0);
                      return sumStart < sumEnd;
                    }
                    return false;
                  }
                  return ipRegex.test(value);
                },
              },
            },
          },
        },
      },
    },
    methods: {
      setIpAddressType(event, expressionIndex) {
        this.currentRule.options.expression[expressionIndex].type = event.key;
      },
      getIntervalValue(value, index) {
        const values = `${value}`.split('|');
        if (values.length === 2) return values[index];
        return index === 0 ? values[0] : '';
      },
      setIntervalValue(expIndex, value, index) {
        const currentValue = this.currentRule.options.expression[expIndex].value.length
          ? this.currentRule.options.expression[expIndex].value
          : '|';
        const parts = currentValue.split('|');
        parts[index] = value;
        this.currentRule.options.expression[expIndex].value = parts.join('|');
      },
      getErrorMessage(index) {
        const isCool = this.$v.currentRule.options.expression.$each[index].value.isCool;
        const type = this.currentRule.options.expression[index].type;
        if (!isCool)
          return type === 'specific'
            ? this.$t('ip.errors.notValid')
            : this.$t('ip.errors.notValidRange');
        return null;
      },
    },
  };
</script>
