<template lang="pug">
div
  .form-check.form-check-inline.mr-3(
    v-for="type in ['direct', 'google-adwords', 'google-organic', 'facebook']"
  )
    om-checkbox.pl-0.mr-1.text-center(
      :id="optionId(type)"
      type="checkbox"
      :value="isChecked(type)"
      :error="hasCheckboxError"
      @input="changeCheckbox(type, $event)"
      style="max-width: 20px"
    )
    label.form-check-label.settings-label(:for="optionId(type)") {{ getSourceByType(type) }}
  .settings-label.settings-label-larger.my-3 {{ $t('customSource') }}
  .d-flex
    .flex-grow-0.mr-4(v-if="currentRule.options.expression.some((e) => e.source === 'custom')")
      label.settings-label.mt-2 {{ $t('pageUrl') }}:
    .flex-grow-1
      .d-flex.align-items-center.mb-3(
        v-if="expression.source === 'custom'"
        v-for="(expression, expressionIndex) in currentRule.options.expression"
      )
        om-select.col-7(
          :id="`expression-${expressionIndex}`"
          :options="conditionOperators"
          @input="setOperatorForOneExpression($event, expressionIndex)"
          :value="{ key: currentRule.options.expression[expressionIndex].operator, value: $t(currentRule.options.expression[expressionIndex].operator) }"
        )
        om-input.col-5.pl-0(
          :id="`sourcePage-${expressionIndex}`"
          v-model="currentRule.options.expression[expressionIndex].value"
          type="text"
          :show-invalid-suffix="false"
          :error="$v.currentRule.options.expression.$each[expressionIndex].value.$error"
        )
        .brand-link.settings-label.flex-grow-0(
          @click="removeExpression('expression', expressionIndex)"
        )
          fa-icon(variant="fa-trash" size="1.5")
  span.brand-link.settings-label(
    @click="addExpression('expression', { source: 'custom', operator: 'equals', value: '' })"
  )
    fa-icon(variant="fa-plus" :html="`&nbsp;${$t('addNew')}`")
</template>

<script>
  import getSourceByType from '@/mixins/getSourceByType';
  import settingsValidation from '@/mixins/settingsValidation';
  import expressionOperations from '@/mixins/expressionOperations';
  import frontendRuleUtils from '@/mixins/frontendRuleUtils';
  import { required } from 'vuelidate/lib/validators';

  function atLeastOneCheckboxSelected(value) {
    return (
      value.some((expr) =>
        ['direct', 'google-adwords', 'google-organic', 'facebook'].includes(expr.source),
      ) || value.length
    );
  }
  export default {
    mixins: [settingsValidation, expressionOperations, getSourceByType, frontendRuleUtils],

    data() {
      return {
        sourceCheckboxes: [],
        conditionOperators: [
          { key: 'equals', value: this.$t('equals') },
          { key: 'notEquals', value: this.$t('notEquals') },
          { key: 'contains', value: this.$t('contains') },
          { key: 'notContains', value: this.$t('notContains') },
          { key: 'startsWith', value: this.$t('startsWith') },
          { key: 'notStartsWith', value: this.$t('notStartsWith') },
          { key: 'endsWith', value: this.$t('endsWith') },
          { key: 'notEndsWith', value: this.$t('notEndsWith') },
          { key: 'matchRegex', value: this.$t('matchRegex') },
          { key: 'notMatchRegex', value: this.$t('notMatchRegex') },
        ],
      };
    },
    validations: {
      currentRule: {
        options: {
          expression: {
            required,
            atLeastOneCheckboxSelected,
            $each: {
              value: {
                required,
              },
            },
          },
        },
      },
    },
    computed: {
      hasCheckboxError() {
        if (!this.$v.$dirty) return;

        return !this.$v.currentRule.options.expression.atLeastOneCheckboxSelected;
      },
    },

    methods: {
      isChecked(type) {
        return this.currentRule.options.expression.some((e) => e.source === type);
      },

      changeCheckbox(type, checked) {
        if (checked) {
          switch (type) {
            case 'direct':
              this.addExpression('expression', {
                source: type,
                operator: 'equals',
                value: 'direct',
              });
              break;
            case 'google-adwords':
              this.addExpression('expression', {
                source: type,
                operator: 'matchRegex',
                value: '\\?.*(\\??|\\&)gclid=',
              });
              break;
            case 'google-organic':
              this.addExpression('expression', {
                source: type,
                operator: 'matchRegex',
                value: '^.*google\\.[a-z]+\\/?$',
              });
              this.addExpression('expression', {
                source: type,
                operator: 'notMatchRegex',
                value: '\\?.*(\\??|\\&)gclid=',
              });
              break;
            case 'facebook':
              this.addExpression('expression', {
                source: type,
                operator: 'matchRegex',
                value: '^.*facebook\\.com(.*)',
              });
              break;
          }
        } else {
          this.removeSource(type);
        }
      },

      removeSource(type) {
        this.currentRule.options.expression = this.currentRule.options.expression.filter(
          (e) => e.source !== type,
        );
      },

      optionId(type) {
        return type.includes('-')
          ? type
              .split('-')
              .map((s) => s[0])
              .join('')
          : type.charAt(0);
      },
    },
  };
</script>
